/**
 *
 * IndexPage
 *
 *
 */

import React, { useEffect } from 'react';
import styled from 'styled-components';

import { graphql } from "gatsby";

import QuietPlease from 'components/sections/QuietPlease';
import SubmitStory from 'components/blocks/SubmitStory';

import ContentEngine from 'components/content/ContentEngine';
import SectionHeadline from 'components/elements/SectionHeadline';

import FeaturedArticle from 'components/content/FeaturedArticle'


const CarryOnPage = ({ data }) => {
  useEffect(() => {

  });

  console.log(data);
  const entries = data.quietPlease['nodes'];
  const { contentEngine } = data.content;
  const { featuredArticle } = data.content;
  
  
  return (
    <Container>
      <Headline greenText>Quiet Please</Headline>
      <FeaturedArticle entry={featuredArticle} />
      <ContentEngine blocks={contentEngine}/>
      <QuietPlease isOnBlog={false} entries={entries} isOnCarryOnPage={true}/>
      <SubmitStory />
    </Container>
  );
};

CarryOnPage.propTypes = {

};

export default CarryOnPage;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
`;

const Headline = styled(SectionHeadline)`
  margin: 2em 0;
    

  @media all and (min-width: 900px) {
    h2 {
      font-size: 4em;
    }
  }
`;

export const pageQuery = graphql`
  {
    content: craftQuietPleaseHomeQuietPleaseHomeEntry  {
      ... on Craft_quietPleaseHome_quietPleaseHome_Entry {
        featuredArticle {
          ... on Craft_quietPlease_quietPlease_Entry {
            title
            previewText
            featuredImage {
              url
            }
            slug
            contentEngine {
              ... on Craft_contentEngine_richText_BlockType {
                typeHandle
                body
              }
            }
          }
        }
        contentEngine {
          ... on Craft_contentEngine_richText_BlockType {
            typeHandle
            body
          }
          ... on Craft_contentEngine_blockQuote_BlockType {
            typeHandle
            citation
            quote
          }
          ... on Craft_contentEngine_videoEmbed_BlockType {
            typeHandle
            videoUrl
          }
          ... on Craft_contentEngine_headline_BlockType {
            typeHandle
            text
          }
          ... on Craft_contentEngine_lineBreak_BlockType {
            typeHandle
            icon {
              url
            }
          }
          ... on Craft_contentEngine_pullQuote_BlockType {
            typeHandle
            quote
          }
          ... on Craft_contentEngine_cta_BlockType {
            typeHandle
            textColor
            buttonColor
            buttonLink
            buttonText
            newTab
          }
          ... on Craft_contentEngine_photoGallery_BlockType {
            typeHandle
            slide {
              ... on Craft_slide_BlockType {
                id
                image {
                  url
                  focalPoint
                }
              }
            }
          }
          ... on Craft_contentEngine_imageOneUp_BlockType {
            typeHandle
            image {
              url
              focalPoint
            }
          }
          ... on Craft_contentEngine_imageTwoUp_BlockType {
            typeHandle
            images {
              ... on Craft_images_BlockType {
                id
                image {
                  url
                  focalPoint
                }
              }
            }
          }
        }
      }
    }
    quietPlease: allCraftQuietPleaseQuietPleaseEntry (filter: {remoteId: {nin: "1815"}}) {
      nodes {
        ... on Craft_quietPlease_quietPlease_Entry {
          id
          title
          slug
          writer
          postDate
          featuredImage {
            url
          }
        }
      }
    }
  }
`;