import React from 'react';

import styled, { css } from 'styled-components';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'gatsby';



const FeaturedArticle = ({ entry }) => {

  const { previewText, title, featuredImage, contentEngine, slug } = entry[0];
  console.log(entry[0]);
  return (
    <Container>
      <div>
        <LazyLoadImage src={featuredImage[0].url} />
        <h3>{title}</h3>
      </div>
      <p>{previewText}</p>
      <StyledLink isOnBlog={true} to={'/quiet-please/' + slug}>
        Read Article
      </StyledLink>
    </Container>
  )
};


export default FeaturedArticle;

const Container = styled.section`
  width: 100%;
  margin: 2em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  max-width: 700px;
  padding: 0 2em;
  > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  img {
    width: 100%;
    height: auto;
    max-width: 300px;
    object-fit: cover;
    align-self: center;
  }
  h3, p {
    color: ${({ theme }) => theme.colors.Primary};
  }
  h3 {
    font-size: 4em;
    margin: 1em 0;
  }
  p {
    font-size: 2em;
    line-height: 1.5;
    margin: 1em 0;
  }
`;

const StyledLink = styled(Link)`
  font-family: 'Bebas Neue', serif;
  font-size: 2.4em;
  color: ${({ theme }) => theme.colors.Primary};
  border: 2px solid ${({ theme }) => theme.colors.Primary};
  align-self: center;
  padding: 0.2em 1em;
  margin-top: 0.5em;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  ${( { isOnBlog } ) =>
      isOnBlog &&
      css`
        order: 3;
        margin-top: 1.25em;
      `
    };
  &:hover { 
    background-color: ${({ theme }) => theme.colors.Primary};
    color: white;
  }
`;